import sweetAlert from 'sweetalert2/dist/sweetalert2.all'

document.addEventListener('DOMContentLoaded', function() {
  var pcCopyBtn = document.getElementById('pc-copy-referral-url');
  copyReferralLink(pcCopyBtn);
  var spCopyBtn = document.getElementById('sp-copy-referral-url');
  copyReferralLink(spCopyBtn);
})

function copyReferralLink(element) {
  if (element == undefined) {
    return 
  }
  element.addEventListener('click', function() {
    var url = element.getAttribute('data-referral-url')
    var copyFrom = document.createElement("textarea");
    copyFrom.textContent = url;
    var bodyElm = document.getElementsByTagName("body")[0];
    bodyElm.appendChild(copyFrom);
    copyFrom.select();
    var retVal = document.execCommand('copy');
    bodyElm.removeChild(copyFrom);
    sweetAlert.fire({
      title: 'コピーが完了しました',
      html: `<p style="text-align:left;">
        紹介用のURLをコピーしました。<br>
        メール等にペーストすることでNatuloShopの招待リンクを他の方へ送ることができます。<br>
        NatuloShopを周りの方々にご紹介する際にご活用ください。
        </p>
        <p style="text-align:left;">
        コピーしたURL<br>
        ${url}
      </p>`,
      type: 'info',
      confirmButtonText: 'OK'
    })
    return retVal;
  })
}
