import Rails from '@rails/ujs'
import sweetAlert from 'sweetalert2/dist/sweetalert2.all'

if (document.URL.match(/contracts\/new/)) {
  document.addEventListener('DOMContentLoaded', function() {
    var shipping_fee_element = document.getElementById('shipping_fee')
    if (shipping_fee_element != undefined) {
      var origin_shipping_fee = shipping_fee_element.innerText
    }
    var shipping_fee_lists_80 = [
          { prefecture: '北海道', fee: '¥1,304' },
          { prefecture: '青森県', fee: '¥940' },
          { prefecture: '岩手県', fee: '¥940' },
          { prefecture: '宮城県', fee: '¥940' },
          { prefecture: '秋田県', fee: '¥940' },
          { prefecture: '山形県', fee: '¥940' },
          { prefecture: '福島県', fee: '¥940' },
          { prefecture: '富山県', fee: '¥940' },
          { prefecture: '石川県', fee: '¥940' },
          { prefecture: '福井県', fee: '¥940' },
          { prefecture: '新潟県', fee: '¥940' },
          { prefecture: '長野県', fee: '¥940' },
          { prefecture: '茨城県', fee: '¥940' },
          { prefecture: '栃木県', fee: '¥940' },
          { prefecture: '群馬県', fee: '¥940' },
          { prefecture: '埼玉県', fee: '¥940' },
          { prefecture: '千葉県', fee: '¥940' },
          { prefecture: '神奈川県', fee: '¥940' },
          { prefecture: '山梨県', fee: '¥940' },
          { prefecture: '東京都', fee: '¥878' },
          { prefecture: '静岡県', fee: '¥940' },
          { prefecture: '愛知県', fee: '¥940' },
          { prefecture: '岐阜県', fee: '¥940' },
          { prefecture: '三重県', fee: '¥940' },
          { prefecture: '奈良県', fee: '¥1,026' },
          { prefecture: '滋賀県', fee: '¥1,026' },
          { prefecture: '京都府', fee: '¥1,026' },
          { prefecture: '大阪府', fee: '¥1,026' },
          { prefecture: '兵庫県', fee: '¥1,026' },
          { prefecture: '和歌山県', fee: '¥1,026' },
          { prefecture: '岡山県', fee: '¥1,122' },
          { prefecture: '広島県', fee: '¥1,122' },
          { prefecture: '鳥取県', fee: '¥1,122' },
          { prefecture: '島根県', fee: '¥1,122' },
          { prefecture: '山口県', fee: '¥1,122' },
          { prefecture: '徳島県', fee: '¥1,122' },
          { prefecture: '香川県', fee: '¥1,122' },
          { prefecture: '愛媛県', fee: '¥1,122' },
          { prefecture: '高知県', fee: '¥1,122' },
          { prefecture: '福岡県', fee: '¥1,304' },
          { prefecture: '佐賀県', fee: '¥1,304' },
          { prefecture: '長崎県', fee: '¥1,304' },
          { prefecture: '熊本県', fee: '¥1,304' },
          { prefecture: '大分県', fee: '¥1,304' },
          { prefecture: '宮崎県', fee: '¥1,304' },
          { prefecture: '鹿児島県', fee: '¥1,304' },
          { prefecture: '沖縄県', fee: '¥1,392' }
        ]
    var shipping_fee_lists_60 = [
          { prefecture: '北海道', fee: '¥1,224' },
          { prefecture: '青森県', fee: '¥813' },
          { prefecture: '岩手県', fee: '¥813' },
          { prefecture: '宮城県', fee: '¥813' },
          { prefecture: '秋田県', fee: '¥813' },
          { prefecture: '山形県', fee: '¥813' },
          { prefecture: '福島県', fee: '¥813' },
          { prefecture: '富山県', fee: '¥813' },
          { prefecture: '石川県', fee: '¥813' },
          { prefecture: '福井県', fee: '¥813' },
          { prefecture: '新潟県', fee: '¥813' },
          { prefecture: '長野県', fee: '¥813' },
          { prefecture: '茨城県', fee: '¥813' },
          { prefecture: '栃木県', fee: '¥813' },
          { prefecture: '群馬県', fee: '¥813' },
          { prefecture: '埼玉県', fee: '¥813' },
          { prefecture: '千葉県', fee: '¥813' },
          { prefecture: '神奈川県', fee: '¥813' },
          { prefecture: '山梨県', fee: '¥813' },
          { prefecture: '東京都', fee: '¥765' },
          { prefecture: '静岡県', fee: '¥813' },
          { prefecture: '愛知県', fee: '¥813' },
          { prefecture: '岐阜県', fee: '¥813' },
          { prefecture: '三重県', fee: '¥813' },
          { prefecture: '奈良県', fee: '¥909' },
          { prefecture: '滋賀県', fee: '¥909' },
          { prefecture: '京都府', fee: '¥909' },
          { prefecture: '大阪府', fee: '¥909' },
          { prefecture: '兵庫県', fee: '¥909' },
          { prefecture: '和歌山県', fee: '¥909' },
          { prefecture: '岡山県', fee: '¥1,034' },
          { prefecture: '広島県', fee: '¥1,034' },
          { prefecture: '鳥取県', fee: '¥1,034' },
          { prefecture: '島根県', fee: '¥1,034' },
          { prefecture: '山口県', fee: '¥1,034' },
          { prefecture: '徳島県', fee: '¥1,034' },
          { prefecture: '香川県', fee: '¥1,034' },
          { prefecture: '愛媛県', fee: '¥1,034' },
          { prefecture: '高知県', fee: '¥1,034' },
          { prefecture: '福岡県', fee: '¥1,224' },
          { prefecture: '佐賀県', fee: '¥1,224' },
          { prefecture: '長崎県', fee: '¥1,224' },
          { prefecture: '熊本県', fee: '¥1,224' },
          { prefecture: '大分県', fee: '¥1,224' },
          { prefecture: '宮崎県', fee: '¥1,224' },
          { prefecture: '鹿児島県', fee: '¥1,224' },
          { prefecture: '沖縄県', fee: '¥1,273' }
        ]
    
    var shipping_inputs = document.querySelectorAll('.address_field');
    var shipping_form = document.getElementById('shipping_form');
    var check = document.getElementById('contract_ship_to_billing_address');
    if (check != undefined) {
      check.addEventListener('click', function() {
        if (check.checked) {
          shipping_inputs.forEach(function(element) {
            element.required = false;
            element.disabled = true;
            shipping_form.style = 'display:none;';
            shipping_fee_element.innerText = origin_shipping_fee;
          })
        } else {
          shipping_inputs.forEach(function(element) {
            element.required = true;
            element.disabled = false;
            shipping_form.style = 'display:block;';
            shippingFeeCheck();
          })
        }
        submitable()
      })
    }
  
    
    var shipping_zipcode = document.getElementById('contract_shipping_zipcode')
    if (shipping_zipcode != undefined) {
      shipping_zipcode.addEventListener('focusout', function() {
        shippingFeeCheck()
      })  
    }
    
    function shippingFeeCheck() {
      var shipping_prefecture = document.getElementById('contract_shipping_prefecture').value
      var items = document.getElementById('contract_item_id')
      var item_idx = items.selectedIndex
      var item = items.options[item_idx].value
      if (item == 2) {
        var shipping_fee_lists = shipping_fee_lists_60
      } else {
        var shipping_fee_lists = shipping_fee_lists_80
      }
      shipping_fee_lists.find(function(element) {
        if (element.prefecture == shipping_prefecture) {
          shipping_fee_element.innerText = element.fee
        }
      })  
    }
    
    var user_name = document.getElementById('contract_user_name');
    var user_zipcode = document.getElementById('contract_user_zipcode');
    var user_prefecture = document.getElementById('contract_user_prefecture');
    var user_city = document.getElementById('contract_user_city');
    var user_street = document.getElementById('contract_user_street');
    var user_building = document.getElementById('contract_user_building');
    var user_telephone = document.getElementById('contract_user_telephone');
    var item_select = document.getElementById('contract_item_id');
    var item_price = document.getElementById('item_price');
    var shipping_name = document.getElementById('contract_shipping_name');
    var shipping_zipcode = document.getElementById('contract_shipping_zipcode');
    var shipping_prefecture = document.getElementById('contract_shipping_prefecture');
    var shipping_city = document.getElementById('contract_shipping_city');
    var shipping_street = document.getElementById('contract_shipping_street');
    var shipping_building = document.getElementById('contract_shipping_building');
    var shipping_telephone = document.getElementById('contract_shipping_telephone');
    
    const confirmed = (element, result) => {
      if (result.value) {
        // User clicked confirm button
        element.removeAttribute('data-confirm-swal')
        element.click()
      }
    }
  
    const swalFire = (element, message) => {
      sweetAlert.fire({
        title: '下記の内容でよろしいですか?',
        html: message || '',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      }).then(result => confirmed(element, result))
    }
    
    // Display the confirmation dialog
    const showConfirmationDialog = (element) => {  
      var confirm_message = ''
      var idx = item_select.selectedIndex;
      if (check.checked) {
        confirm_message = `
          <div style="text-align:left">
            <p>【商品】${item_select.options[idx].text}</p>
            <p>【価格】${item_select.options[idx].getAttribute('data-price')}</p>
            <p>【送料】${shipping_fee_element.innerText}</p>
            <p>【送付先氏名】${user_name.value}</p>
            <p>【送付先郵便番号】${user_zipcode.value}</p>
            <p>【送付先都道府県】${user_prefecture.value}</p>
            <p>【送付先市区町村】${user_city.value}</p>
            <p>【送付先番地】${user_street.value}</p>
            <p>【送付先建物名】${user_building.value}</p>
            <p>【送付先電話番号】${user_telephone.value}</p>
          </div>
        `
        swalFire(element, confirm_message)
      } else {
        if (shipping_name.value && shipping_prefecture.value && shipping_city.value && shipping_street.value && shipping_telephone.value) {
          confirm_message = `
            <div style="text-align:left">
              <p>【商品】${item_select.options[idx].text}</p>
              <p>【価格】${item_select.options[idx].getAttribute('data-price')}</p>
              <p>【送料】${shipping_fee_element.innerText}</p>
              <p>【送付先氏名】${shipping_name.value}</p>
              <p>【送付先郵便番号】${shipping_zipcode.value}</p>
              <p>【送付先都道府県】${shipping_prefecture.value}</p>
              <p>【送付先市区町村】${shipping_city.value}</p>
              <p>【送付先番地】${shipping_street.value}</p>
              <p>【送付先建物名】${shipping_building.value}</p>
              <p>【送付先電話番号】${shipping_telephone.value}</p>
            </div>
          `
          swalFire(element, confirm_message)
        }
      }
    }
  
    if (shipping_name != undefined) {
      shipping_name.addEventListener('change', function() {
        submitable()
      })
  
      shipping_zipcode.addEventListener('change', function() {
        submitable()
      })
  
      shipping_prefecture.addEventListener('change', function() {
        submitable()
      })
  
      shipping_city.addEventListener('change', function() {
        submitable()
      })
  
      shipping_street.addEventListener('change', function() {
        submitable()
      })
  
      shipping_telephone.addEventListener('change', function() {
        submitable()
      })
    }
  
    
    const allowAction = (element) => {
      if (element.getAttribute('data-confirm-swal') === null) {
        return true
      }
    
      showConfirmationDialog(element)
      return false
    }
    
    function handleConfirm(element) {
      if (!allowAction(this)) {
        Rails.stopEverything(element)
      }
    }
  
    var submit = document.getElementById('contract_submit');
    function submitable() {
      if (check.checked || (shipping_name.value && shipping_prefecture.value && shipping_city.value && shipping_street.value && shipping_telephone.value)) {
        submit.disabled = false
        submit.parentNode.classList.remove('disabled');
      } else {
        submit.disabled = true
        submit.parentNode.classList.add('disabled');
      }
    }
  
    Rails.delegate(document, 'input[data-confirm-swal]', 'click', handleConfirm)
  })  
}
