import Rails from '@rails/ujs'
import sweetAlert from 'sweetalert2/dist/sweetalert2.all'


document.addEventListener('DOMContentLoaded', function() {
  var partnerCode = document.getElementById('user_partner_code');
  var submitBtn = document.getElementById('submit')
  if (document.URL.match(/^users\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/edit/) && partnerCode) {
    partnerCode.addEventListener('change', function() {
      if (partnerCode.value.match(/\d{10}/)) {
        submitBtn.setAttribute('data-confirm-swal', '')
        Rails.delegate(document, 'input[data-confirm-swal]', 'click', handleConfirm)  
      } else {
        submitBtn.removeAttribute('data-confirm-swal')
      }
    })

    const confirmed = (element, result) => {
      if (result.value) {
        // User clicked confirm button
        element.removeAttribute('data-confirm-swal')
        element.click()
      }
    }
  
    const swalFire = (element, message) => {
      sweetAlert.fire({
        title: '下記の内容でよろしいですか?',
        html: message || '',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      }).then(result => confirmed(element, result))
    }
    
    // Display the confirmation dialog
    const showConfirmationDialog = (element) => {  
      if (!partnerCode.value) {
        element.removeAttribute('data-confirm-swal')
        return
      }
      var message = `
        <p style="text-align:left" class="m-0">外部サービスIDを登録します。</p>
        <p style="text-align:left" class="m-0">外部サービスIDは一度登録すると変更はできかねます。</p>
        <p style="text-align:left" class="m-0">現在入力されているIDは【${partnerCode.value}】です。</p>
        <p style="text-align:left" class="m-0">登録してよろしいですか？</p>
      `
      swalFire(element, message)
    }
    
    const allowAction = (element) => {
      if (element.getAttribute('data-confirm-swal') === null) {
        return true
      }
    
      showConfirmationDialog(element)
      return false
    }
    
    function handleConfirm(element) {
      if (!allowAction(this)) {
        Rails.stopEverything(element)
      }
    }
  }
})
