// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')

import '../stylesheets/application'
import $ from 'jquery';
import 'materialize-css/dist/js/materialize'
import '@fortawesome/fontawesome-free/js/all'
import './contract_form'
import './referral_link'
import './partner_code'
import 'select2';
import 'jquery-jpostal-ja'

document.addEventListener('DOMContentLoaded', (event) => {
  M.AutoInit();

  var elems = document.querySelectorAll('.dropdown-trigger');
  var instances = M.Dropdown.init(elems, { coverTrigger: false });
})

$(document).ready(function() {
  $('.searchable').select2({width: "100%"});
  $("[id$='_zipcode']").jpostal({
    postcode : ["[id$='_zipcode']"],
    address : {
      "[id$='_prefecture']"  : '%3',
      "[id$='_city']"  : '%4',
      "[id$='_street']"  : '%5'
    }
  });
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")